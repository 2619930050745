<template>
  <div class="movie-hall page">
    <van-nav-bar
        class="nav-bar"
        :title="$t('video.video')"
    />
    <div style="margin-bottom: 70px;">
      <van-pull-refresh :pulling-text='$t("list.pulling")' :loosing-text='$t("list.loosing")'
      :loading-text='$t("list.loading")' :success-text='$t("list.success")' v-model="isLoading" @refresh="onRefresh">
            <div class="hot-recommend-div" style="display: none;">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    :immediate-check="false"
                    :finished-text="$t('video.no_more')"
                    @load="onLoad"
                >
                  <div class="list-item">
                    <div class="movie-list-item" v-for="(v,key) in videolist" :key="key" @click="toPlayVideo(v.id)">
                      <van-image class="cover_img"  round :src="v.vod_pic">
                        <template v-slot:loading>
                          <van-loading type="circular"/>
                        </template>
                      </van-image>
                      <div class="movie-list-item-bottom">
                        <div class="movie-time-div">
                          <span>{{v.vod_name}}</span>
                          <span>{{$t("video.play")}}:{{v.count}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </van-list>

            </div>
            <div class="hot-recommend-div" style="margin-bottom: 20px;">
          <van-col span="12" v-for="(item, index) in hotG" :key="index">
            <div style="margin: 10px 8px 0px;border: 1px solid #f23ed0;height: 350px;overflow: auto;border-radius: 8px;"  @click="toGInfo(item)">
              <div
                style="margin: 10px 10px 0px 10px;padding: 0px;position: relative;"
              >
                <img
                  :src="item.img_url"
                  style="width: 100%;height: 200px;object-fit: cover;"
                />
                <div
                  style="background-color: rgb(000, 000, 000,0.8);position:absolute;top: 0px;left: 0px;line-height:38px;width: 100%;padding: 0px 10px;text-align: left;white-space:nowrap;overflow:hidden;color: #fff;"
                >
                  {{ item.yn_yu }}
                </div>
              </div>
              <div style="margin: 30px 10px">
                <div
                  style="float: left;width: 30px;height: 30px;"
                  v-for="i in item.x"
                  :key="i"
                >
                  <img
                    src="../images/item/g0.png"
                    style="width: 100%;height: 100%;"
                  />
                </div>
                <div style="clear: both;" />
              </div>
              <div style="margin: 10px 10px;display: none;">
                <div style="float: left;width: 30px;height: 30px;">
                  <img
                    src="../images/item/g3.png"
                    style="width: 100%;height: 100%;"
                  />
                </div>
                <div
                  style="float: left;margin-left: 8px;line-height: 24px;font-weight: 600;"
                >
                  {{ item.o }}
                </div>
                <div style="clear: both;" />
              </div>
              <div style="margin: 10px 10px">
                <div style="float: left;width: 30px;height: 30px;">
                  <img
                    src="../images/item/g1.png"
                    style="width: 100%;height: 100%;"
                  />
                </div>
                <div
                  style="float: left;margin-left: 8px;line-height: 2;font-weight: 600;"
                >
                  {{ showAddress(item.class_id) }}
                </div>
                <div style="clear: both;" />
              </div>
              <div style="margin: 10px 10px;display: none;">
                <div style="float: left;width: 30px;height: 30px;">
                  <img
                    src="../images/item/g2.png"
                    style="width: 100%;height: 100%;"
                  />
                </div>
                <div
                  style="float: left;margin-left: 8px;line-height: 2.2;font-weight: 600; "
                >
                  {{ item.p }}
                </div>
                <div style="clear: both;" />
              </div>
            </div>
          </van-col>
        </div>
          </van-pull-refresh>
    </div>
   <div style="height: 100px;width: 100%;">&nbsp;</div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      hotG:[],
      addlist:[],
      active: 0,
      isLoading: false,
      count:0,
      loading: false,
      finished: false,
      refreshing: false,
      videolitem: [],
      videolist: [],
      number:0,
      page:0,
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      }
    };
  },
  methods: {
    toGInfo(data){
      let vod_name = ''
      this.addlist.forEach(element => {
        if(data.class_id == element.id)vod_name = element.yn_yu
      });
       this.$router.push({ path: '/profile?id=' + data.id + '&name=' + vod_name + '&adsid=' + data.class_id });
     // http://192.168.12.186:8080/#/profile?id=8&name=B%E1%BA%AFc%20Ninh&adsid=32
    },
    getAddress() {
      this.$http({
        method: "get",
        url: "address_list",
      }).then((res) => {
        this.addlist = res.data;
        this.getxuanfeihome();
      });
    },
    showAddress(id) {
      let result = id;
      this.addlist.forEach((element) => {
        if (id == element.id) result = element.yn_yu;
      });
      return result;
    },
    getxuanfeihome() {
      this.hotG = [];
      this.$http({
        method: "get",
        url: "xuanfeiall",
      }).then((res) => {
        res.data.forEach((item) => {
            try {
              item.x = parseInt(item.xuanfei_name.split("^")[2]);
              if (isNaN(item.x)) item.x = 1;
            } catch (error) {
              console.log(error);
              item.x = 1;
            }
            try {
              item.o = parseInt(item.xuanfei_name.split("^")[0]);
              if (isNaN(item.o)) item.o = 18;
            } catch (error) {
              console.log(error);
              item.o = 18;
            }
            try {
              item.p = item.xuanfei_name.split("^")[1];
            } catch (error) {
              console.log(error);
            }
            this.hotG.push(item);
          
        });
      });
    },
    getVideoClass(){
      this.$http({
        method: 'get',
        url: 'video_class'
      }).then(res=>{
        this.videolitem = res.data;
      })
    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }

    },
    itemChange(){
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList(){
      this.$http({
        method: 'get',
        data:{id:this.active,page:this.page},
        url: 'video_list'
      }).then(res=>{
        this.videolist = this.videolist.concat(res.data.data);
        this.count = res.data.count;
        this.page++;

      })
    },
    onLoad() {
        this.getVideoList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.videolist = [];
          this.refreshing = false;
        }
        this.loading = false;
        if (this.videolist.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
     OnChange(){
      this.videolist = [];
      this.number = 0;
      this.page = 0;
      this.count = 0;
      this.getVideoList();//获取视频列表

    },
    onRefresh() {
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        this.onLoad();
        this.isLoading = false;
        Toast(this.$t("reservation.refresh"));
      }, 500);
    },
  },
  created() {
    this.getVideoClass();//获取视频类目
    this.OnChange()
    this.getAddress();
  }
};
</script>

<style lang='less' scoped>
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar{
  background: linear-gradient(
      90deg,#f806e4,#e6c3a1);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}

.movie-hall{
  display: flex;
  flex-direction: column;
  bottom: 100px;
  background: #f2f2f5;
}
::v-deep .van-tabs__nav {
  background: linear-gradient(to right,#f806e4, #e6c3a1);
}
::v-deep .van-tab {
  color: #ffffff;
  font-size: 30px;
}
::v-deep .van-tabs__line {
  bottom: 15px;
  width: 55px;
  height: 7px;
  border-radius: 0px;
  background-color: #ffffff;
}

::v-deep .van-tab__text--ellipsis{
 line-height: 1.2;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 5px 23px;
}
::v-deep  .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.video_swiper {
  width: 100%;
  flex: 1;
  .swiper-slide {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    justify-content: center;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
}
.movie-list-tab {
  overflow: auto;
  height: 100%;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000;
  font-size: 35px;
}
.movie-list-tab .hot-recommend-div{
  height: 100%;
  margin: 10px auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  //overflow: auto;
}
.list-item{
  display: flex;
  width: calc(100% - 50px);
  margin: 10px auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.list-item .movie-list-item:nth-child(odd) {
  margin-right: 20px;
}
.movie-list-item .cover_img{
  border-radius: 20px;
  width:335px;
  height:290px;
}
.movie-list-item{
  margin-bottom: -10px;
}
.list-item .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;
}
.list-item .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.list-item .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.list-item .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.list-item .movie-time-div {
  color: #fff;
  border-radius: 0 0 20px 20px;
  height: 35px;
}
</style>
